import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"10"}},[_c('div',{attrs:{"align":"center","jusitfy":"center"}},[_c(VContainer,{staticClass:"iframe-wrapper"},[_c('div',{staticClass:"iframe-container"},[_c('iframe',{attrs:{"allow":"camera; microphone","id":"kioskApp","src":"https://dekalb-kiosks-demo.netlify.app","frameborder":"1px"}}),_c('span',[_vm._v(_vm._s(_vm.innWidth))])])])],1)]),_c(VCol,{staticClass:"px-2",attrs:{"cols":"2","align-self":"center"}},[_c(VRow,{staticClass:"pa-2",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"ma-2",attrs:{"color":"error","block":""},on:{"click":function($event){return _vm.logout()}}},[_vm._v("Click to Reload")])],1),_c(VCol,{attrs:{"cols":"12"}},[_c('span',{staticClass:"ma-2"},[_vm._v("Login Credentials:")])]),_c(VCol,{attrs:{"cols":"12"}},[_c('span',{staticClass:"ma-2"},[_vm._v("Email: kiosk1@arsconnect.com")])]),_c(VCol,{attrs:{"cols":"12"}},[_c('span',{staticClass:"ma-2"},[_vm._v("Password: backspace")])]),_c(VCol,{attrs:{"cols":"12"}},[_c(VAlert,{staticClass:"ma-2",attrs:{"dense":"","type":"info"}},[_vm._v(" You may have to referesh the Application twice if you are unable to see FAQs/Maps options after you login. ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }